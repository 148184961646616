<template>
  <div class="content" id="next-delivery">
    <ResultsHeader title="Openstaande bestellingen (boeken)" />
    <div class="results-main">
      <table class="table is-hoverable is-fullwidth result-table" v-if="!isBusy">
        <thead>
        <tr>
          <th style="width: 30%;">Klant - Perceel</th>
          <th>Datum</th>
          <th style="width: 25%">Product</th>
          <th>Referentie</th>
          <th style="width: 10%;">Status</th>
          <th style="width: 10%;">Te&nbsp;leveren</th>
        </tr>
        </thead>
        <template v-for="(result, key) in data" v-bind:key="key">
          <tbody class="result-table__group">
          <tr @click="openResultGroup(key)">
            <td :colspan="5">
              {{ key }}
              <font-awesome-icon icon="caret-up" v-if="resultGroupsStatus[key]"/>
              <font-awesome-icon icon="caret-down" v-else/>
            </td>
            <td>{{ totals[key] }}</td>
          </tr>
          </tbody>
          <tbody v-if="resultGroupsStatus[key]">
          <tr v-for="book in result" v-bind:key="book" class="result-table__item">
            <td>{{ book['customer_parcel'] }}</td>
            <td>{{ formatDate(book['order_date']) }}</td>
            <td>{{ book['description'] }}</td>
            <td v-if="book['library_ref']">{{ book['library_ref'] }}</td>
            <td v-else></td>
            <td>{{ book['product_status'] }}</td>
            <td>{{ book['qty_to_deliver'] }}</td>
          </tr>
          </tbody>
        </template>
      </table>
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import ResultsHeader from "@/app/Components/ResultsHeader";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";

export default {
  name: 'NextDelivery',
  components: {
    LoadingBlock,
    BackButton,
    ResultsHeader
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  methods: {
    openResultGroup(key) {
      this.resultGroupsStatus[key] = !this.resultGroupsStatus[key];
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  },
  setup() {
    const data = ref([]);
    const totals = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/to_deliver')
          .then((response) => {
            let results = response.data;
            let columnAmounts = []

            // Sort function
            const groupBy = function (data, key) {
              return data.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };

            // Remove delivered rows
            // Calc columnAmounts
            results.forEach(function (value, key) {
              if (value['qty_to_deliver'] === 0) {
                delete results[key];
              } else {
                if (!columnAmounts[value['customer_parcel']]) {
                  columnAmounts[value['customer_parcel']] = 0;
                }
                columnAmounts[value['customer_parcel']] += value['qty_to_deliver'];
              }
            })

            // Group by to_deliver amount
            data.value = groupBy(results, 'customer_parcel');
            totals.value = columnAmounts;

            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });
    return {
      data,
      totals
    }
  }
};

</script>
<style lang="scss">
#next-delivery {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
