<template>
  <div class="tile box is-primary is-child">
    <p class="title">Zoek een factuur op</p>
    <p class="description">Hier kun je een factuur opzoeken aan de hand van het nummer van de factuur of de pakbon.</p>
    <div class="field has-addons">
      <p class="control is-expanded">
        <input class="input" type="text" placeholder="Zoeken..." v-model="input.searchInput" v-on:keyup.enter="search">
      </p>
      <p class="control">
        <a class="button is-primary" v-on:click="search">
          <font-awesome-icon icon="search"/>
        </a>
      </p>
    </div>
  </div>
</template>

<script>

import {useRouter} from "vue-router";
import {reactive} from "@vue/reactivity";

export default {
  name: "SearchInvoicesBlock",
  setup() {
    const router = useRouter()
    const input = reactive({searchInput: ""});

    function search() {
      if (input.searchInput) {
        let searchQuery = input.searchInput;
        searchQuery = `${encodeURIComponent(searchQuery)}`
        if (searchQuery.substring(0, 4) === 'PAKB') {
          router.push({name: 'invoices-search', query: {'type': 'packingslip', 'id': input.searchInput}});
        } else {
          router.push({name: 'invoices-search', query: {'type': 'invoice', 'id': input.searchInput}});
        }
      }
    }

    return {
      search,
      input,
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .description {
    margin-bottom: 33px;
  }

  .select::after {
    border-color: #A7A7A7;
  }
}
</style>
